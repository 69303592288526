<template>
    <div v-if="this.pageData" class="declaration">
        <HeaderIntroImage marginbottom :headerMetadata="this.pageData" menuparent="get involved" />
        <main>
            <section class="green-banner" v-if="this.pageData.attributes.field_description_w_summary.summary">
                <div class="container">
                    <p> {{ this.pageData.attributes.field_description_w_summary.summary }} </p>
                </div>
            </section>
            <section class="main-content">
                <div class="container">
                    <!-- <h4>Sign the Science in Exile Declaration</h4> -->
                    <p v-html="this.pageData.attributes.field_description_w_summary.value"></p>
                    <h4>Read the Science in Exile Declaration</h4>
                    <attachments v-if="pageData" :attachmentData="pageData"></attachments>
                    <div class="buttons">
                        <p>
                            <a class="sign button" href="https://forms.office.com/r/KUJ1P20hmu">SIGN THE
                                DECLARATION<br><span>for Organization</span></a></p>
                        <p>
                            <a class="sign button" href="https://forms.office.com/r/mhLW8FDtda">SIGN THE
                                DECLARATION<br><span>for Individuals</span></a></p>
                    </div>
                </div>
                <br>
                <div class="container" v-if="this.pageData.attributes.field_youtube_video_code">
                    <h4>Watch the video of the Science in Exile Declaration</h4>
                    <p>Scholars from the five continents who are currently displaced, have been displaced, or come from
                        displacement hotspots, read out the Declaration</p>
                    <div class="margin video-container">
                        <iframe allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                            frameborder="0" class="image"
                            :src="'https://www.youtube.com/embed/' + this.pageData.attributes.field_youtube_video_code">
                        </iframe>
                    </div>
                </div>
                <br>
                <div class="endorsers-count">
                    <div class="container">
                        <h2>Endorsers so far</h2>
                        <div>

                            <div>
                                <div>
                                    <img src="../assets/icon-organizations.svg" alt="">
                                </div>
                                <div>
                                    <h3 v-if="this.organizationsCount">{{this.organizationsCount}}</h3>
                                    <p>Organizations</p>
                                </div>

                            </div>
                            <div>
                                <div>
                                    <img src="../assets/icon-individuals.svg" alt="">
                                </div>
                                <div>
                                    <h3 v-if="this.individual_endorsers">{{this.individual_endorsers}}</h3>
                                    <p>Individuals</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="endorsers" v-if="filterPreEndorsers.length || filterEndorsers.length">
                    <div class="container" v-if="filterPreEndorsers.length">
                        <h2 class="container">Pre-endorsers</h2>
                        <div>
                            <div class="endorser" v-for="(e,index) in filterPreEndorsers" :key="index">
                                <div class="image">

                                    <a v-if="linkend(e)" target="_blank"
                                        :href="e.attributes.field_endorser_link_to_website.uri">
                                        <img v-if="e.field_endorser_logo"
                                            :src="endpoint + e.field_endorser_logo.attributes.uri.url" alt="">
                                    </a>
                                    <a v-else>
                                        <img v-if="e.field_endorser_logo"
                                            :src="endpoint + e.field_endorser_logo.attributes.uri.url" alt="">
                                    </a>
                                </div>
                                <p>
                                    {{e.attributes.title}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="container ">
                        <h2 class="container" v-if="filterEndorsers.length">Endorsers</h2>
                        <div>
                            <div class="endorser" v-for="(e,index) in filterEndorsers" :key="index">
                                <div class="image">
                                    <a v-if="linkend(e)" target="_blank"
                                        :href=" e.attributes.field_endorser_link_to_website.uri">
                                        <img v-if="e.field_endorser_logo"
                                            :src="endpoint + e.field_endorser_logo.attributes.uri.url" alt="">
                                    </a>
                                    <a v-else>
                                        <img v-if="e.field_endorser_logo"
                                            :src="endpoint + e.field_endorser_logo.attributes.uri.url" alt="">
                                    </a>
                                </div>
                                <p>
                                    {{e.attributes.title}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="note-bottom">
                    <div class="container">
                <p><em><strong>*</strong>The links to the websites of the organizations endorsing the Science in Exile Declaration are provided for informational purposes only; they do not constitute an endorsement, guarantee or approval by UNESCO-TWAS, IAP or ISC of any of the products, services or opinions of the organizations or individuals. UNESCO-TWAS, IAP or ISC bear no responsibility for the accuracy, legality or content of the external websites or for that of subsequent links, nor accept any liability for errors or omissions or for loss or damage arising from accessing the websites or using the content/ information contained within. Please contact the external websites for answers to questions regarding its content.</em></p>
            </div></div>
            </section>
            
        </main>
    </div>
</template>

<script>
    import Attachments from '../components/Attachments.vue'
    import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
    import {
        fetchNodes,
        fetchSinglePage
    } from '../libs/drupalClient'
    export default {
        name: 'sign-declaration',
        components: {
            HeaderIntroImage,
            Attachments
        },
        data: () => {
            return {
                pageData: null,
                currentPageID: "a96208e8-555d-4b46-bcff-9ff777425c07",
                endorser: [],
                individual_endorsers: null,
                organizationsCount: null
            }
        },
        methods: {
            linkend(e) {
                if (e.attributes.field_endorser_link_to_website)
                    return e.attributes.field_endorser_link_to_website.uri
                else return ''
            },
        },
        computed: {
            endpoint() {
                return process.env.VUE_APP_ENDPOINT
            },
            filterPreEndorsers() {
                return this.endorser.filter(e => e.attributes.field_endorser_category == 'Pre-endorser')
                    .sort((a, b) => {
                        return a.attributes.title > b.attributes.title ? 1 : -1
                    })
            },
            filterEndorsers() {
                return this.endorser.filter(e => e.attributes.field_endorser_category == 'Endorser')
                    .sort((a, b) => {
                        return a.attributes.title > b.attributes.title ? 1 : -1
                    })
            },
        },

        async mounted() {

            fetchSinglePage(this.currentPageID, {
                    include: ['field_page_banner', 'field_page_images', 'field_ref_ui_text', 'field_attachments'
                    ]
                })
                .then(res => {
                    this.pageData = res
                    console.log(res);
                })

            await fetchNodes('endorser', {
                include: ['field_endorser_logo']
            }).then(res => {
                this.endorser = res
                this.organizationsCount = this.endorser.length
            })

            await fetchNodes('individual_endorsers', false).then(res => {
                console.log('this.individual_endorsers', res);
                this.individual_endorsers = res[0].attributes.field_total_individual_endorsers
            })


        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/variables.scss";
    .note-bottom {
        background-color: #ebf0f5;
    }
  .note-top ::v-deep strong, 
    .note-bottom ::v-deep strong {
        font-size: 1.5em;
        line-height: 1rem;
        color: $orange;
        font-weight: 700;
    }

    .note-bottom ::v-deep p {
        font-size: .9rem;
        line-height: 1.2rem;
            margin: 0 auto;
    padding: 1rem 0 4rem 0;
    }


.main-content .buttons p {
    padding: 1rem 0;
}
    h2 {
        padding: 0;
        margin-bottom: 1rem;
    }

    .main-content h4 {
        margin: 2rem auto 0 auto;
    }

    .sign.button {
        max-width: 200px;
    }

    .declaration .main-content {
        padding-bottom: 0;
    }

    .main-content .attachments-container {
        font-size: 1rem;
        margin: 0 auto;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        margin-top: 1rem;
        /* border-bottom: 1px solid rgb(216, 216, 216); */
    }

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        height: 0;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .endorsers {
        padding: 2rem 0rem;
        background-color: #EBF0F5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }

        .image a {
            box-shadow: 0 7px 20px 0 rgb(0 0 0 / 9%);
            background: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80vw;
            height: 80vw;
            max-width: 270px;
            max-height: 270px;
        }

        a:hover {
            img {
                opacity: 0.6;
                transition: 0.2s;
            }
        }

        >.container:last-child {
            margin-top: 9rem;
        }
    }

    .endorser {
        /* margin-bottom: 2rem; */
        max-width: 270px;


        img {
            width: auto;
            height: auto;
            max-width: 70%;
            max-height: 70%;
        }

        p {
            text-align: center;
            font-size: 18px;
        }
    }




    /*
    ENDORSERS COUNT DOWN
    */

    .buttons {
        display: flex;
        flex-direction: column;

        a {
            margin: 0rem auto;

            span {
                font-weight: 300;

            }
        }
    }

    .endorsers-count {
        padding: 2rem 0rem;
        background-color: #030D26;

        h2 {
            text-align: center;
        }

        >div>div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 1rem auto 2rem auto;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 1rem 0rem;
            }
        }

        h2 {
            color: #fff;
        }

        h3 {
            color: #83BA26;
            font-size: 70px;
            line-height: 70px;
            font-weight: 500;
        }

        p {
            color: #fff;
            font-weight: 500;
            font-size: 20px;
            margin-top: 0rem;
        }

        img {
            max-width: 100px;
            opacity: 0.9;
        }
    }

    @media (min-width: 768px) {
        .endorsers>.container>div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        .endorsers-count {
            >div>div {
                display: flex;
                flex-direction: row;
                margin-bottom: 0;
            }

            >div>div>div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 1rem auto 2rem auto;

                div {
                    align-items: flex-start;
                }

                div>div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            h2 {}

            h3 {}

            p {}

            img {
                margin-bottom: 0;
                margin-right: 2rem;
            }
        }
    }

    @media (min-width: 992px) {
        /* .endorsers > .container > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        } */

        //.endorser { margin-right: 20px; }
    }

    @media (min-width: 1024px) {

        h2 {
            margin-bottom: 2rem;
        }

        .endorsers>.container>div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .endorsers {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .endorser {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;

                p {
                    margin-top: 1rem;
                }

            }
        }
    }

    @media (min-width: 1140px) {
        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;

            p {
                margin: 2rem 0;
            }

            a {
                margin: 1rem 2rem;

                span {}
            }
        }

        .endorsers-count {
            padding: 3rem 0rem;
        }

        .endorsers-count>div>div {
            margin-top: 4rem;
            position: relative;

            &:before {
                background-color: rgb(255, 255, 255);
                height: 100%;
                width: 1px;
                content: "";
                position: absolute;
                top: 37%;
                opacity: 0.3;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, -50%) rotate(17deg);
            }

            div {
                margin-top: 0;

                p {
                    margin-bottom: 0;
                }
            }

            h3 {
                font-size: 92px;
                line-height: 90px;
            }

            p {
                font-size: 30px;
            }

            img {
                margin-right: 3rem;
                max-width: 126px;
            }
        }
    }

    @media (min-width: 1300px) {
        .endorsers>.container>div {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .container {
            width: 100%;
        }

        .video-container {
            padding-bottom: 44%;
        }
    }
</style>